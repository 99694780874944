import { createStore } from 'vuex'

export default createStore({
  state: {
    brandConfig: undefined,
    user: undefined,
    showWinDailyBanner: true,
    acquisitionConfig: undefined,
    authError: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setShowWinDailyBanner(state, showWinDailyBanner) {
      state.showWinDailyBanner = showWinDailyBanner;
    },
    setAcquisitionConfig(state, acquisitionConfig) {
      state.acquisitionConfig = acquisitionConfig;
    },
    setBrand(state, brandConfig) {
      state.brandConfig = brandConfig;
    },
    setAuthError(state, authError) {
      state.authError = authError;
    },
  },
  actions: {
  },
  modules: {
  }
})
