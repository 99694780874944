import {environmentConfig} from "@/config/environmentConfig";
import axios from "axios";
const basicAuthEncoded = window.btoa(`${process.env.VUE_APP_QUIZ_INC_BASIC_AUTH_USERNAME}:${process.env.VUE_APP_QUIZ_INC_BASIC_AUTH_PASSWORD}`).toString('base64');
const headers = { Authorization: `Basic ${basicAuthEncoded}` };
import { Buffer } from 'buffer';

export default class QuizIncService {
    static async getUserViaMsisdn(msisdn, sessionId) {
        try {
            return await axios.post(`${environmentConfig.quizIncApi}/get-user-via-msisdn`, {
                    msisdn,
                    serviceId: `${environmentConfig.quizIncServiceId}`,
                    sessionId
                },
                {headers}
            );
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaSubscriptionId(subscriptionId) {
        return await axios.post(`${environmentConfig.quizIncApi}/get-user-via-subscription-id`, {
                subscriptionId: subscriptionId,
                serviceId: `${environmentConfig.quizIncServiceId}`
            },
            {headers}
        )
    }

    static async requestOtp(msisdn) {
        return await axios.post(`${environmentConfig.quizIncApi}/request-otp`, {
                msisdn,
                serviceId: `${environmentConfig.quizIncServiceId}`
            },
            {headers}
        )
    }

    static async validateOtp(msisdn, otp) {
        return await axios.post(`${environmentConfig.quizIncApi}/validate-otp`, {
                msisdn,
                otp,
                serviceId: `${environmentConfig.quizIncServiceId}`
            },
            {headers}
        )
    }

    static async doiOne(msisdn, sessionId) {
        try {
            return await axios.post(`${environmentConfig.quizIncApi}/doi-one`, {
                    origination: 'Fanclash - Web',
                    msisdn,
                    sessionId: sessionId,
                    serviceId: `${environmentConfig.quizIncServiceId}`
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async doiTwo(msisdn, sessionId) {
        try {
            return await axios.post(`${environmentConfig.quizIncApi}/doi-two`, {
                    origination: 'Fanclash - Web',
                    msisdn,
                    sessionId: sessionId,
                    serviceId: `${environmentConfig.quizIncServiceId}`
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async subscribe(msisdn, sessionId) {
        try {
            return await axios.post(`${environmentConfig.quizIncApi}/subscribe`, {
                    msisdn,
                    serviceId: `${environmentConfig.quizIncServiceId}`,
                    acquisitionChannel: "Organic-Web",
                    sessionId: sessionId
                },
                {headers}
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async createSessionId(msisdn) {
        return await axios.post(`${environmentConfig.quizIncApi}/create-session-id`, {
                msisdn,
                serviceId: `${environmentConfig.quizIncServiceId}`
            },
            {headers}
        )
    }

    static async decryptMsisdn(encryptedMsisdn) {
        const response = await axios.post(`${environmentConfig.dataDecryptionHost}/decrypt-enriched-msisdn`, {
            encryptedMsisdn
        })
        return response.data.msisdn
    }

    static async getEvinaScript(te, msisdn) {
        const response = await axios.post(`${environmentConfig.quizIncApi}/get-evina-script`, {
                targetElement: te,
                msisdn: msisdn,
                serviceId: "vc-fanclash-quizinc-01"
            }, {headers}
        )
        return Buffer.from(response.data.script.toString(), "base64").toString();
    }
}
