import {createRouter, createWebHistory} from 'vue-router'
import store from '../store/index'
import LandingPage from '../views/LandingPage.vue'
import OtpPage from "@/views/OtpPage";
import PlayPage from "@/views/PlayPage";
import ErrorPage from "@/views/ErrorPage";
import App from "@/App";

const routes = [
  {
    path: '/',
    name: App,
    component: App,
    props: true
  },
  {
    path: '/landing-page',
    name: 'LandingPage',
    component: LandingPage,
    props: true
  },
  {
    path: '/otp',
    name: 'OtpPage',
    component: OtpPage,
    props: true
  },
  {
    path: '/play',
    name: 'PlayPage',
    component: PlayPage,
    props: true
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  if (!store.state.acquisitionConfig) {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedMsisdn = urlParams.get('encryptedMsisdn')
    if (encryptedMsisdn) localStorage.setItem('encryptedMsisdn', encryptedMsisdn)
    const campaignId = urlParams.get('campaignId') || urlParams.get('cid') || 0;
    const acquisitionChannel = urlParams.get('acquisitionChannel') || 'Organic-Web';
    const revenuePartnerId = urlParams.get('revenuePartnerId') || 27;
    store.commit('setAcquisitionConfig', {campaignId, acquisitionChannel, revenuePartnerId});
  }
  if(!hasQueryParams(to) && hasQueryParams(from)){
    const params = getQueryStringParams(to, from);
    next({name: to.name, query: params});
  } else {
    next();
  }
})

function getQueryStringParams(to, from) {
    if (hasQueryParams(from)) return from.query;
    else if (hasQueryParams(to)) return to.query;
}

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

export default router
