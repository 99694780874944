<template>
  <div class="body-container" v-if="!authError">
    <div>
      <div class="d-flex flex-column text-center subscription-error-text pt-5">
        Oops! There was a problem creating your subscription!
      </div>
      <div class="d-flex justify-content-center">
        <button class="mt-3 try-again-button col-10" @click="onRetryClicked">TRY AGAIN</button>
      </div>
    </div>
  </div>

  <div class="body-container" v-if="authError">
    <div>
      <div class="d-flex flex-column text-center subscription-error-text pt-5">
        Oops! You session has expired.
      </div>
      <div class="d-flex justify-content-center">
        <button class="mt-3 try-again-button col-10" @click="onRetryClicked">KEEP PLAYING</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import getPathWithQueryParameter from "@/functions/getPathWithQueryParameter";
import store from "@/store";

export default {
  name: "ErrorPage",
  computed: {
    ...mapState(['acquisitionConfig', 'brandConfig']),
  },
  data() {
    return {
      authError: undefined
    }
  },
  methods: {
    onRetryClicked() {
      const encryptedMsisdn = localStorage.getItem('encryptedMsisdn')
      if (encryptedMsisdn) this.$router.push(`${getPathWithQueryParameter('/landing-page')}`)
      if (!encryptedMsisdn) this.$router.push(`${getPathWithQueryParameter('/otp')}`)
    },
  },
  beforeMount() {
    this.authError = store.state.authError;
    store.commit('setAuthError', {authError: false})
  }
}
</script>

<style scoped>
.subscription-error-text {
  font-size: 18px;
  color: white;
}

.try-again-button {
  color: white;
  background-color: #EF151C;
  border-radius: 30px;
  height: 55px;
  border: 0;
  font-size: 18px;
  font-weight: 600;
}
</style>
