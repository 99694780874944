<template>
  <div class="d-flex flex-column text-center" v-if="validMsisdn">
    <div v-if="!hastOtpSent && !otpLimitExceeded && !otpAlreadyUsed && !otpExpired">
      <div class="pt-4 enter-number-header-text">
        Enter your phone number to join Quiz Inc VIP!
      </div>
      <div class="pt-2 enter-number-secondary-text">
        Enter your number below
      </div>
      <div class="mt-3 input-box">
        <input v-model="msisdn" v-on:change="msisdnConversion" type="text" placeholder="Enter your number"
               class="input-number-text col-10">
      </div>
      <div class="mt-1">
        <button @click="continueButtonClicked" class="col-10 continue-button">CONTINUE</button>
      </div>
      <div class="mt-4 p-2 terms-and-conditions-container">
        This service costs R 3/day. Cancel anytime: dial *135*997#. Full T&C visit <a class="terms-and-conditions-link"
                                                                                      href="https://quizinc.co.za/terms_and_conditions">T's
        & C's</a>.
      </div>
    </div>

    <div v-if="hastOtpSent && !otpLimitExceeded && !otpAlreadyUsed && !otpExpired">
      <div class="otp-sent-text pt-4">
        An SMS has been sent to your account holder to authorize your subscription
      </div>
      <div class="mt-2 enter-pin-secondary-text" v-if="!invalidOtp">
        Enter the PIN below
      </div>
      <div class="mt-2 enter-pin-secondary-text" v-if="invalidOtp">
        The OTP entered was incorrect please try again by entering the PIN below
      </div>
      <div class="mt-3 input-box">
        <input v-model="otp" type="text" placeholder="Enter OTP" class="input-otp-text col-10">
      </div>
      <div class="mt-4" v-on:click="getStartedClicked">
        <button class="col-10 get-started-button">GET STARTED!</button>
      </div>
      <div class="mt-3 try-again-sms-text">
        Didn't receive the SMS? Try Again
      </div>
      <div class="mt-4 p-3 terms-and-conditions-container">
        This service costs R 3/day. Cancel anytime: dial *135*997#. Full T&C visit <a class="terms-and-conditions-link"
                                                                                      href="https://quizinc.co.za/terms_and_conditions">T's
        & C's</a>.
      </div>
    </div>
  </div>
  <div class="d-flex flex-column text-center" v-if="otpLimitExceeded">
    <div>
      <div class="invalid-msisdn-text pt-5">
        You have exceeded your OTP attempt limit, click <a @click="continueButtonClicked"><b style="color: #EF151C">RESEND
        OTP</b></a> to receive a new OTP.
      </div>
    </div>
  </div>
  <div class="d-flex flex-column text-center" v-if="otpAlreadyUsed">
    <div>
      <div class="invalid-msisdn-text pt-5">
        This OTP has already been used, click <a @click="continueButtonClicked"><b style="color: #EF151C">RESEND OTP</b></a>
        to receive a new OTP.
      </div>
    </div>
  </div>
  <div class="d-flex flex-column text-center" v-if="otpExpired">
    <div>
      <div class="invalid-msisdn-text pt-5">
        This OTP has expired, click <a @click="continueButtonClicked"><b style="color: #EF151C">RESEND OTP</b></a> to
        receive a new OTP.
      </div>
    </div>
  </div>
  <div class="d-flex flex-column text-center" v-if="!validMsisdn">
    <div>
      <div class="invalid-msisdn-text pt-5">
        We have identified that you are using an invalid number. Please use a valid number to subscribe to Quiz Inc
        VIP at R3/day.
      </div>
      <div class="mt-1">
        <button @click="goBackToEnterMsisdn" class="col-10 continue-button">CONTINUE</button>
      </div>
    </div>
  </div>
</template>

<script>
import QuizIncService from "@/services/quizIncService";
import {mapMutations, mapState} from 'vuex';
import getPathWithQueryParameter from "@/functions/getPathWithQueryParameter";
import {handleAuthError} from "@/functions/handleAuthError";

export default {
  name: "OtpPage",
  data() {
    return {
      msisdn: undefined,
      hastOtpSent: false,
      otp: undefined,
      validMsisdn: true,
      otpLimitExceeded: false,
      otpAlreadyUsed: false,
      otpExpired: false,
      invalidOtp: false
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapMutations(['setUser']),
    goBackToEnterMsisdn() {
      this.msisdn = undefined;
      this.validMsisdn = true;
    },
    async continueButtonClicked() {
      this.invalidOtp = false;
      this.otpExpired = false;
      this.otpAlreadyUsed = false;
      this.otpLimitExceeded = false;
      const isValidMsisdn = this.isMsisdnValid(this.msisdn);
      if (isValidMsisdn) {
        await QuizIncService.requestOtp(this.msisdn.toString());
        this.hastOtpSent = true;
        await this.embedEvinaScript();
      }
      if (!isValidMsisdn) this.validMsisdn = false;
    },
    async getStartedClicked() {
      const response = await QuizIncService.validateOtp(this.msisdn, this.otp);
      if (response.data.sessionId) {
        const sessionId = response.data.sessionId;
        this.setSessionId(sessionId);
        const userResponse = await QuizIncService.getUserViaMsisdn(this.msisdn, sessionId);
        if (userResponse?.authError) return await handleAuthError();

        this.setUser(userResponse.data);
        if (this.user.status !== "Active") {
          if ((await QuizIncService.doiTwo(this.msisdn, sessionId)?.authError)) return await handleAuthError();
          const response = await QuizIncService.subscribe(this.msisdn, sessionId);
          if (response?.authError) return await handleAuthError();
          if (response.data.status === 'Active') {
            return this.$router.push(`${getPathWithQueryParameter('/play')}`);
          } else if (!response.data.result) return this.$router.push(`${getPathWithQueryParameter('/error')}`);
        } else {
          return this.$router.push(`${getPathWithQueryParameter('/play')}`);
        }
      } else {
        if (response.data.OTPAttemptCount > 1) {
          this.otpLimitExceeded = true;
          this.hastOtpSent = true;
          return;
        } else this.invalidOtp = true;
      }
      if (response.data.UsedOTP) {
        this.hastOtpSent = true;
        this.otpAlreadyUsed = true;
        this.invalidOtp = true;
        return;
      }
      if (response.data.ExpiredOTP) {
        this.hastOtpSent = true;
        this.otpExpired = true;
        this.invalidOtp = true;
        return;
      }
      return this.$router.push(`${getPathWithQueryParameter('/error')}`);

    },
    msisdnConversion() {
      if (this.msisdn.startsWith('0')) {
        this.msisdn = this.msisdn.slice(1);
        const saNumber = '27';
        this.msisdn = parseInt(saNumber + this.msisdn, 10);
      } else if (this.msisdn.startsWith('+')) {
        this.msisdn = this.msisdn.slice(1);
      }
    },
    isMsisdnValid() {
      return this.msisdn.toString().length === 11;
    },
    setSessionId(sessionId) {
      localStorage.setItem('sessionId', sessionId);
    },
    async embedEvinaScript() {
      const evinaScriptResponse = await QuizIncService.getEvinaScript("button.get-started-button", this.msisdn);
      let evinaScript = document.createElement('script');
      evinaScript.text = evinaScriptResponse.toString();
      document.head.appendChild(evinaScript)
    },
  }
}
</script>

<style scoped>
.enter-number-header-text, .invalid-msisdn-text, .otp-sent-text {
  font-size: 17px;
  color: white;
}

.enter-number-secondary-text, .enter-pin-secondary-text {
  color: #696969;
}

.input-number-text, .input-otp-text {
  height: 50px;
  border-radius: 12px;
  border: #000000 0px solid;
  padding-left: 14px;
  font-size: 18px;
  color: #696969;
}

.continue-button, .get-started-button {
  color: white;
  background-color: #EF151C;
  border-radius: 30px;
  height: 55px;
  border: 0;
  font-size: 18px;
  font-weight: 600;
}

.terms-and-conditions-container {
  background-color: #312b2b;
  color: #696969;
  font-size: 14px;
  width: 100%;
}

.try-again-sms-text {
  color: white;
  text-decoration: underline;
}

.terms-and-conditions-link {
  color: #FFFFFF;
}
</style>
