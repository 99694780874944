<template>
  <div class="text-center quiz-inc-container pt-3">
    <div class="p-2 click-yes-text">
      Click Yes below to subscribe to Quiz Inc VIP from Vodacom
    </div>
    <div class="subscribe-price-text">
      R 3/day subscriptions service
    </div>
    <div class="justify-content-center pt-3">
      <button @click="yesButtonClicked" class="yes-button col-10">YES</button>
    </div>
    <div class="pt-3 cancel-text" @click="cancelButtonClicked()">
      Cancel
    </div>
    <div class="mt-4 p-3 terms-and-conditions-container">
      <div class="terms-and-conditions-text">
        This service costs R 3/day. Cancel anytime: dial *135*997#. Full T&C visit <a class="terms-and-conditions-link" href="https://quizinc.co.za/terms_and_conditions">T's & C's</a>
      </div>
    </div>
  </div>

  <div v-if="cancelled" class="text-center">
    <div class="cancel-request-text pt-5">
      You have successfully cancelled the request for Quiz Inc VIP from Vodacom
    </div>
    <div class="mt-5 p-3 terms-and-conditions-container">
      <div class="terms-and-conditions-text">
        This service costs R 3/day. Cancel anytime: dial *135*997#. Full T&C visit <a class="terms-and-conditions-link" href="https://quizinc.co.za/terms_and_conditions">T's & C's</a>
      </div>
    </div>
  </div>
</template>

<script>
import QuizIncService from "@/services/quizIncService";
import {mapMutations, mapState} from "vuex";
import getPathWithQueryParameter from "@/functions/getPathWithQueryParameter";
import {getSessionId} from "@/functions/getSessionId";
import {handleAuthError} from "@/functions/handleAuthError";

export default {
  name: 'LandingPage',
  data() {
    return {
      cancelled: false,
    }
  },
  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapMutations(['setUser']),
    async yesButtonClicked() {
        await this.doiTwoSubscribe(this.user.msisdn)
    },
    async doiTwoSubscribe(msisdn) {
      const sessionId = localStorage.getItem('sessionId');
      await this.checkForErrorResponse(await QuizIncService.doiTwo(msisdn, sessionId));

      const response = await QuizIncService.subscribe(msisdn, sessionId);
      await this.checkForErrorResponse(response);
      if (response.data.result) this.$router.push(`${getPathWithQueryParameter('/play')}`)
      if (!response.data.result) this.$router.push(`${getPathWithQueryParameter('/error')}`)
    },
    async getUserByEncryptedMsisdn() {
      const encryptedMsisdn = localStorage.getItem('encryptedMsisdn');
      if (encryptedMsisdn) {
        const msisdn = await QuizIncService.decryptMsisdn(encryptedMsisdn);
        const sessionId = await getSessionId(msisdn);
        const userResponse = await QuizIncService.getUserViaMsisdn(msisdn,sessionId);
        await this.checkForErrorResponse(userResponse);
        this.setUser(userResponse.data);
        if (userResponse.data.status === "Active") this.$router.push(`${getPathWithQueryParameter('/play')}`);
      }
    },
    cancelButtonClicked() {

    },
  },
  async beforeMount() {
    await this.getUserByEncryptedMsisdn()
  },
  async checkForErrorResponse(responseObject){
    if(responseObject?.authError) return await handleAuthError();
  }
}
</script>

<style type="text/css">
.terms-and-conditions {
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-size: 12px;
}

.click-yes-text, .cancel-request-text {
  color: white;
  font-size: 18px;
}

.subscribe-price-text, .terms-and-conditions-text {
  color: #696969;
  font-size: 14px;
}

.yes-button {
  color: white;
  background-color: #EF151C;
  border-radius: 30px;
  height: 55px;
  border: 0;
  font-size: 20px;
  font-weight: 600;
}

.cancel-text {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.terms-and-conditions-container {
  background-color: #312b2b;
}

.terms-and-conditions-link {
  color: #FFFFFF;
}
</style>
