import {brandConfigMap} from "@/brand";
import store from "@/store";

export function setBrandConfig() {
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain);
    if (!brandConfig) {
        const brandConfig = brandConfigMap.get('default');
        store.commit('setBrand', brandConfig);
        console.log(brandConfig);
    } else {
        store.commit('setBrand', brandConfig);
        console.log(brandConfig);
    }
}
