export default (path) => {
    return `${path}${window.location.search}`;

//     if (queryParams && name) next({name: name, query: queryParams});
//     else if (name) next({name: name});
//     else next();
}

//
// function getQueryStringParams(to, from) {
//     if (hasQueryParams(from)) return from.query;
//     else if (hasQueryParams(to)) return to.query;
// }