<template>
  <div class="d-flex flex-column body-container align-items-center text-center">
    <div class="p-2 mt-3 col-10 airtime-win-text">
      Stand a chance to win your share of R1.5 million in airtime & more!
    </div>
    <button @click="playNowButtonClicked" class="col-11 play-now-button mt-5">Play Now!</button>
    <div class="d-flex flex-column justify-content-center mt-5 terms-and-conditions-container">T & Cs Apply |
      Copyright.
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import QuizIncService from "@/services/quizIncService";
import {environmentConfig} from "@/config/environmentConfig";

export default {
  name: "PlayPage",
  computed: {
    ...mapState(['brandConfig', 'user']),
  },
  data() {
    return {
      brandConfigMzanzi: 'vc-mzanzi-en-za',
      brandConfigQuiz: 'vc-quiz-en-za',
      displayQuizInc: 'true',
    }
  },
  methods: {
    ...mapMutations(['setUser']),
    playNowButtonClicked() {
      window.open(`${environmentConfig.quizIncGameHost}&qi-subscription-id=${this.user.subscriptionId}`, '_self');
    }
  },
  async beforeMount() {
    //TODO check if this is needed
    const sessionId = localStorage.getItem('sessionId');
    const user = await QuizIncService.getUserViaMsisdn(this.user.msisdn, sessionId);
    this.setUser(user.data);
  }
}
</script>

<style scoped>
.body-container {
  color: white;
}

.airtime-win-text {
  font-weight: 700;
}

.play-now-button {
  color: white;
  background-color: #EF151C;
  border-radius: 30px;
  height: 45px;
  border: 0;
  font-size: 20px;
  font-weight: 600;
}

.terms-and-conditions-container {
  height: 40px;
  background-color: #312b2b;
  color: #696969;
  font-size: 14px;
  width: 100%;
}


</style>

