<template>
  <div id="app"
       :style="{'background-image': 'url(' + require(`./brand/${brandConfig}/assets/background-image.png`) + ')'}">
    <div v-if="showWinDailyBanner" class="d-flex col justify-content-center pt-1">
      <img class="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-4 header-image"
           :src="require(`./brand/${brandConfig}/assets/main-banner.png`)">
    </div>
    <div class="row justify-content-center">
      <router-view class="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-4"></router-view>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import {setBrandConfig} from "@/functions/setBrandConfig";
import QuizIncService from "@/services/quizIncService";
import getPathWithQueryParameter from "@/functions/getPathWithQueryParameter";
import {getSessionId} from "@/functions/getSessionId";
import {handleAuthError} from "@/functions/handleAuthError";

export default {
  name: 'App',
  computed: {
    ...mapState(['showWinDailyBanner', 'brandConfig'])
  },
  methods: {
    ...mapMutations(['setShowWinDailyBanner', 'setDecryptedMsisdn', 'setUser']),
    setFavicon() {
      const favicon = document.getElementById("favicon");
      favicon.href = require(`./brand/${this.brandConfig}/assets/favicon.png`)
    },
    setBrowserTabTitle() {
      document.getElementById("browserTitle").innerHTML = 'Quiz Inc'
    },
    async isUserActive(msisdn, sessionId) {
      const response = await QuizIncService.getUserViaMsisdn(msisdn, sessionId)
      if(response?.authError) return await handleAuthError();
      this.setUser(response.data);
      return response.data?.status === "Active"
    }
  },
  async beforeMount() {
    setBrandConfig();
    this.setFavicon()
    this.setBrowserTabTitle();

    const encryptedMsisdn = localStorage.getItem('encryptedMsisdn')
    if (encryptedMsisdn) {
      const msisdn = await QuizIncService.decryptMsisdn(encryptedMsisdn);
      if (msisdn) {
        const sessionId = getSessionId(msisdn)
        const isUserActive = await this.isUserActive(msisdn, sessionId);
        if (isUserActive) this.$router.push(`${getPathWithQueryParameter('/play')}`);
        if (!isUserActive) {
          const response = await QuizIncService.doiOne(msisdn, sessionId);
          if(response?.authError) return await handleAuthError();
          this.$router.push(`${getPathWithQueryParameter('/landing-page')}`);
        }
      }
    } else {
      this.$router.push(`${getPathWithQueryParameter('/otp')}`);
    }
  }
}
</script>

<style>
#app {
  background-size: cover;
  min-height: 100vh;
}

.header-image {
  height: auto;
}
</style>
